import React from 'react';

import Row from './Row';
import Image from './Image';

function Columns(props) {
  const { columns } = props;
  return columns && columns.length ? (
    <Row cols={columns.length}>
      {columns.map((col, idx) => (
        <Image {...col} key={`columns-${idx}`} />
      ))}
    </Row>
  ) : null;
}

export default Columns;
