import React from 'react';
import styled from 'styled-components';
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

const InlineImage = ({ mainImage, width }) => {
  const imgUrl =
    mainImage &&
    imageUrlFor(buildImageObj(mainImage))
      .auto("format")
      .url();

  return imgUrl ? <Image src={imgUrl} alt={mainImage.alt || ""} /> : <></>;
};

export default InlineImage;

const Image = styled.img`
  display: flex;
  flex-direction: column;
`;