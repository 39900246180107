import React from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import styled from 'styled-components';
import { BiTimeFive } from "react-icons/bi";

import { getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import Row from './Row';

const RecentNews = props => {
  const { buttonText, heading, items } = props;
  return items && items.length ? (
    <>
        <h2>{heading}</h2>
        <Row cols={'4'}>
            {items.map((item, x) => (
                <Link to={getBlogUrl(item.slug.current)} key={`recent-news-item-${x}`}>
                    <Article>
                        <Card>
                            <CardImage
                                alt={item.title}
                                src={imageUrlFor(item.mainImage).url()}
                            />
                            <CardBody>
                            <Tag><span>{item.categories[0].title}</span></Tag>
                            <h3>
                                {item.title}
                            </h3>
                            <Info>
                                <p />
                                <ReadTime>
                                <BiTimeFive />
                                {format(item.publishedAt, "MMMM Do, YYYY")}
                                </ReadTime>
                            </Info>
                            </CardBody>
                        </Card>
                    </Article>
                </Link>
            ))}
            
        </Row>
        <ButtonElement to={'/latest-news'}>{buttonText}</ButtonElement>
    </>
  ) : null;
};

export default RecentNews;

const Article = styled.article`
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s ease 0s;
`;

const Card = styled.div`
  display: flex;
  height: 100%;

  @media (min-width: 600px) {
    flex-direction: column;
  }
`;

const CardImage = styled.img`
  height: 180px;
  width: 110px;
  min-width: 110px;
  object-fit: cover;

  @media (min-width: 600px) {
    height: 200px;
    min-height: 200px;
    width: 100%;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 16px;

  @media (min-width: 600px) {
    height: 100%;
    padding: 16px 32px;
  }

  h3 {
    font-size: 16px;
    margin: 16px 0 0;
    line-height: 1.3;

    @media (min-width: 600px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 16px;
    margin: 8px 0 0;

    @media (min-width: 600px) {
      font-size: 18px;
      margin: 16px 0;
    }
  }
`;

const Tag = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 4px;
  margin: -4px;
  border-radius: 7px;
  background: white;

  @media (min-width: 600px) {
    margin: -30px 0 0 -20px;
  }

  span {
    display: flex;
    align-content: center;
    justify-content: center;
    color: #181917;
    font-size: 12px;
    font-weight: bolder;
    padding: 2px 7px 5px;
    border-radius: 5px;
    background: #00fed0;

    @media (min-width: 600px) {
      font-size: 14px;
      padding: 4px 8px 8px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding: 0;
`;

const ReadTime = styled.p`
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px -2px 0;
  }
`;

const ButtonElement = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #424242;
    background: #ededed00;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
    margin-top: 16px;

    @media (min-width: 600px) {
        width: auto;
    }

    @media (min-width: 1024px) {
        margin-top: 32px;
    }

    &:hover {
        border: 1px solid #14A58A;
    }

    &:active {
        transform: scale(.95);
    }

    svg {
        margin-left: 16px;
    }
`;