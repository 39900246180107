import React from "react";
import InlineImage from "./InlineImage";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import LatexRenderer from "./Latex";

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <InlineImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    },
    math: ({ node, isInline = false }) => <LatexRenderer isInline={isInline} latex={node.latex} />,
  },
  marks: {
    emailLink: ({ children, mark }) => <a href={`mailto:${mark.email}`} target='_blank' rel='noopener noreferrer'>{children}</a>,
    link: ({ children, mark }) => {
      return mark.blank ? <a href={mark.href} target='_blank' rel='noopener noreferrer'>{children}</a> : <a href={mark.href}>{children}</a>
    },
    internalLink: ({ mark, children }) => {
      const ref = mark.reference;
      const {slug = {}} = ref
      const href = `/${slug.current}`
      return <a href={href}>{children}</a>
    }
  }
};

export default serializers;
