import React from 'react';
import styled, { css } from 'styled-components';

import Row from './Row';
import PortableText from "./portableText";

function Stats(props) {
    return (
        <StatsBlock hasStats={props.stats}>
            <StatsContent hasStats={props.stats}>
                <h1>{props.title}</h1>
                <PortableText blocks={props.body} />
            </StatsContent>
            {props.stats && (
              <StatsCards>
                <Row cols={props.stats.length}>
                    {props.stats.map(stat => (
                        <StatCard key={stat._key}>
                            <Category>{stat.title}</Category>
                            <Stat>{stat.statValue}</Stat>
                            <p>{stat.content}</p>
                        </StatCard>
                    ))}
                </Row>
              </StatsCards>
            )}
        </StatsBlock>
    );
}

export default Stats;

const StatsBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  min-height: ${({hasStats}) => hasStats ? '400px' : 'auto'};
`;

const StatsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 0 24px 0;

  @media (min-width: 600px) {
    max-width: 70%;
    padding: 0 32px 0px 80px;
    margin-bottom: ${({hasStats}) => hasStats ? '80px' : '0px'};
  }

  @media (min-width: 1024px) {
    max-width: 70%;
  }

  h1 {
    width: 100%;
    line-height: 1;

    @media (min-width: 1024px) {
      max-width: 80%;
    }
  }

  p {
    width: 100%;
    line-height: 1.4;
    font-size: 16px;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;

const StatsCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 90%;
  background: white;
  padding: 16px;
  border-radius: 10px;

  @media (min-width: 600px) {
    padding: 0 64px 48px;
    border-radius: 0 0 10px 10px;
  }
`;

const Category = styled.h5`
  font-weight: 700 !important;;
  font-size: 16px !important;
  margin: 0 !important;;
  color: #14A58A;
`;

const Stat = styled.h4`
  font-weight: 900 !important;
  font-size: 30px !important;
  margin: 0 !important;

  @media (min-width: 600px) {
    font-size: 40px !important;
  }
`;

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  background: white;
  border-radius: 10px;
  padding: 16px;

  @media (min-width: 600px) {
    margin: -50px 0 0 0;
    padding: 16px 24px 24px;
    box-shadow: 0px 10px 32px -10px rgba(0,0,0,0.1);
  }

  p {
    margin: 0;
    line-height: 1.2;

    @media (min-width: 600px) {
      font-size: 16px !important;
    }
  }
`;