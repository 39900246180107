import React from "react";
import styled from "styled-components";
import _get from "lodash/get";
import insta from '../images/instagram.png';

function Instagram() {
  return (
    <CtaBlock className={"CtaBlock"} background={"white"}>
        <CtaContent className={"CtaContent"}>
            <Row cols={"1"}>
                <IconWrapper className={'IconWrapper'}>
                    <IconHeader>Latest work</IconHeader>
                    <ImageLink target="_blank" rel="noopener" href={`https://www.instagram.com/mobilebikerepair/`}>
                      <Image src={insta} />
                    </ImageLink>
                </IconWrapper>
            </Row>
         </CtaContent>
    </CtaBlock>
  );
}

export default Instagram;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  grid-gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(${(props) => (props.cols === "1" ? "1" : "2")}, minmax(0px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-gap: 32px;
    margin-bottom: 32px;
    grid-template-columns: repeat(${(props) => props.cols}, minmax(0px, 1fr));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const CtaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 0;
  background: ${(props) => props.background};
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease 0s;
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 24px;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 60%;
    padding: 32px;
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;

const IconWrapper = styled.div`
  padding: 16px 0;

  @media (min-width: 1024px) {
    padding: 32px 0;
  }
`;

const IconHeader = styled.h4`
  font-size: 22px !important;
  font-weight: bold;
  padding: 0px 0 16px;
  margin: 0 !important;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
    font-size: 24px;
  }
`;

const ImageLink = styled.a`
  display: block;
`;

const Image = styled.img`
  display: flex;
  width: 100%;
`;