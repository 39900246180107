import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import Space from './Space';
import PortableText from "./portableText";
import Hero from './hero';
import InfoRows from "./InfoRows";
import CTAColumns from "./cta-columns";
import CTA from "./cta";
import OpeningHours from './OpeningHours';
import Location from './Location';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Stats from './Stats';
import Team from './Team';
import IconRow from './IconRow';
import Video from './Video';
import Image from './Image';
import Carousel from './Carousel';
import Accordion from './Accordion';
import RecentNews from './recent-news';
import HeroColumns from './HeroColumns';
import Columns from './Columns';
import { HealthAdvice } from './healthAdvice';
import { BookAppointment } from './bookAppointment';
import Contact from './Contact_page';

function MainContent(props) {
    const mainComponents = (props.main || []).filter(c => !c.disabled).map((c, i) => {
      let el = null;

      switch (c._type) {
        case "mainContent":
          el = <MainContent key={c._key} {...c} />;
          break;
        case "generalArticle":
          el = <PortableText key={c._key} blocks={c.content} />;
          break;
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />;
          break;
        case "iconRow":
          el = <IconRow key={c._key} {...c} />;
          break;
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "ctaColumns":
          el = <CTAColumns key={c._key} {...c} />;
          break;
        case "heroColumns":
          el = <HeroColumns key={c._key} {...c} />;
        case "columns":
          el = <Columns key={c._key} {...c} />;
        case "ctaPlug":
          el = <CTA key={c._key} {...c} />;
          break;
        case "accordion":
          el = <Accordion key={c._key} {...c} />;
          break;
        case "recentNews":
          el = <RecentNews key={c._key} {...c} />;
          break
        case "statPlug":
          el = <Stats key={c._key} {...c} />;
          break;
        case "video":
          el = <Video key={c._key} {...c} />;
          break;
        case "imageBlock":
          el = <Image key={c._key} {...c} />;
          break;
        case "team":
          el = <Team key={c._key} {...c} />;
          break;
        case "carousel":
          el = <Carousel key={c._key} {...c} />;
          break;
        case "uiComponentRef":
          switch (c.name) {
            case "Space":
              el = <Space key={c._key} />;
              break;
            case "healthAdvice":
              el = <HealthAdvice key={c._key} />;
              break;
            case "bookAppointment":
              el = <BookAppointment key={c._key} />;
              break;
            case "contactPage":
              el = <Contact key={c._key} />;
              break;
            default:
              break;
          }
          break;
        default:
          el = null;
      }
      return el;
    });

    const sidebarComponents = (props.sidebar || []).filter(c => !c.disabled).map((c, i) => {
        let el = null;
  
        switch (c._type) {
          case "mainContent":
            el = <MainContent key={c._key} {...c} />;
            break;
        case "location":
            el = <Location key={c._key} site={props.site} />;
            break;
        case "facebook":
          el = <Facebook key={c._key} site={props.site} />;
            break;
        case "instagram":
          el = <Instagram key={c._key} {...c} />;
          break;
        case "openingHours":
            el = <OpeningHours key={c._key} site={props.site} />;
            break;
          case "infoRows":
            el = <InfoRows key={c._key} {...c} />;
            break;
          case "hero":
            el = <Section type={'slim'} layout={'column'} key={c._key}><Hero {...c} /></Section>;
            break;
          case "ctaColumns":
            el = <CTAColumns key={c._key} {...c} />;
            break;
          case "video":
            el = <Video key={c._key} {...c} />;
            break;
          case "image":
            el = <Image key={c._key} {...c} />;
            break;
          case "ctaPlug":
            el = <CTA key={c._key} {...c} />;
            break;
          case "uiComponentRef":
            switch (c.name) {
              case "Space":
                el = <Space key={c._key} />;
                break;
              default:
                break;
            }
            break;
          default:
            el = null;
        }
        return el;
      });

    return (
        <Section type={'slim'} layout={'row'} style={{justifyContent: 'space-between'}}>
            <Main>          
            <Article>
                <Content>
                    {mainComponents}
                </Content>
            </Article>
            </Main>
            <Aside>
                {sidebarComponents}
            </Aside>
        </Section>
    );
}

export default MainContent;

const Article = styled.article`
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s ease 0s;

  section {
    width: 100%;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1024px) {
    width: 30%;
    margin-left: 32px;
    margin-top: 0;
    position: sticky;
    top: 120px;
  }

  .CtaBlock {
    .CtaContent {
      max-width: 100%;

      h2 {
        font-size: 20px;

        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }

      .IconWrapper {
        padding: 0;

        .Icon {
          padding: 0;
          margin: -10px 0 4px -3px;
        }
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media (min-width: 1024px) {
    padding: 32px;
  }

  .CtaBlock {
    &:hover {
      box-shadow: none;
    }

    .CtaContent {
      padding: 0;

      @media (min-width: 1024px) {
        padding: 32px 0;
      }
    }
  }

  img {
    display: flex;
    width: 100%;
    border-radius: 10px;
  }
`;