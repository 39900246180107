import React from "react";
import styled from 'styled-components';
import PortableText from "../components/portableText";
import CTALink from "./CTALink";
import Section from "./Section";

const CTA = ({ title, body, ctas }) => (
  <Section type={'full'} layout={'column'}>
    <CtaBlock background={'white'}>
      <CtaContent>
        <h2>{title}</h2>
        <PortableText blocks={body} />
        {(ctas || []).map((c, i) => (
          <CTALink
            key={`cta_${i}`}
            {...c}
          />
        ))}
      </CtaContent>
    </CtaBlock>
  </Section>
);

export default CTA;

const CtaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 0;
  background: ${props => props.background};
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease 0s;
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 24px;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 60%;
    padding: 32px;
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;