import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Section from '../components/Section';
import Space from '../components/Space';
import Hero from '../components/hero';
import InfoRows from "../components/InfoRows";
import CTAColumns from "../components/cta-columns";
import CTA from "../components/cta";
import MainContent from "../components/MainContent";
import { HealthAdvice } from "../components/healthAdvice";
import { BookAppointment } from '../components/bookAppointment';
import Contact from '../components/Contact_page';
import Stats from '../components/Stats';
import Video from '../components/Video';
import Image from '../components/Image';
import Carousel from '../components/Carousel';
import CategoryList from '../components/CategoryList';
import Accordion from '../components/Accordion';
import IconRow from '../components/IconRow';
import HeroColumns from '../components/HeroColumns';
import Columns from '../components/Columns';
import RecentNews from '../components/recent-news';

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      location
      openingHours
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
      contactNumber
      companyEmailAddress
    }
  }
`;

const Page = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const content = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null;

      switch (c._type) {
        case "mainContent":
          el = <MainContent key={c._key} {...c} site={site} />;
          break;
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />;
          break;
        case "hero":
          el = <Section key={c._key} type={'slim'} layout={'column'}><Hero {...c} /></Section>;
          break;
        case "ctaColumns":
          el = <CTAColumns key={c._key} {...c} />;
          break;
        case "heroColumns":
          el = <Section key={c._key} type={'slim'} layout={'column'}><HeroColumns {...c} /></Section>;
          break;
        case "columns":
          el = <Section key={c._key} type={'slim'}><Columns {...c} /></Section>;
          break;
        case "carousel":
          el = <Section key={c._key} type={'slim'} layout={'column'}><Carousel {...c} /></Section>;
          break;
        case "ctaPlug":
          el = <Section key={c._key} type={'slim'} layout={'column'}><CTA {...c} /></Section>;
          break;
        case "categoryList":
          el = <CategoryList key={c._key} {...c} />;
          break;
        case "iconRow":
          el = <Section key={c._key} type={'slim'} layout={'column'}><IconRow {...c} /></Section>;
          break;
        case "accordion":
          el = <Section key={c._key} type={'slim'} layout={'column'}><Accordion {...c} /></Section>;
          break;
        case "recentNews":
          el = <Section key={c._key} type={'slim'} layout={'column'}><RecentNews {...c} /></Section>;
          break;
        case "statPlug":
          el = <Section key={c._key} type={'slim'} layout={'column'}><Stats {...c} /></Section>;
          break;
        case "video":
          el = <Section key={c._key} type={'slim'} layout={'column'}><Video {...c} /></Section>;
          break;
        case "imageBlock":
          el = <Section key={c._key} type={'slim'} layout={'column'}><Image {...c} /></Section>;
          break;
        case "uiComponentRef":
          switch (c.name) {
            case "Space":
              el = <Space key={c._key} />;
              break;
            case "healthAdvice":
              el = <HealthAdvice key={c._key} />;
              break;
            case "bookAppointment":
              el = <BookAppointment key={c._key} />;
              break;
            case "contactPage":
              el = <Contact key={c._key} />;
              break;
            default:
              break;
          }
          break;
        default:
          el = null;
      }
      return el;
    });

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = data.route && !data.route.useSiteTitle && page.title;

  return (
    <Layout navMenuItems={menuItems}>
      <SEO
        title={pageTitle || 'Mobile bike repair'}
        description={site.description}
        keywords={site.keywords}
        recaptcha={true}
      />
      <Helmet>
        <script async={true} defer={true} crossOrigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v15.0&appId=1992702547666259&autoLogAppEvents=1" />
      </Helmet>
      {content}
    </Layout>
  );
};

export default Page;
