import React from 'react';
import styled from 'styled-components';

function Button({text, link, type, style, disabled}) {
  return (
    <ButtonElement
        {...(style && { style: {...style} })}
        type={type ? type : 'button'}
        {...((type !== 'submit' || type !== 'reset') && { onClick: () => window.location.href = link })}
        disabled={disabled}
    >
        {text}
    </ButtonElement>
  );
}

export default Button;

const ButtonElement = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #424242;
    background: #ededed00;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
    margin-top: 16px;

    @media (min-width: 600px) {
        width: auto;
    }

    @media (min-width: 1024px) {
        margin-top: 32px;
    }

    &:hover {
        border: 1px solid #14A58A;
    }

    &:active {
        transform: scale(.95);
    }

    svg {
        margin-left: 16px;
    }

    &:disabled {
        cursor: not-allowed;
        color: #b7b5b5;

        &:hover {
            border: 1px solid #d8d8d8;
        }
    }
`;