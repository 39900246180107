import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import Space from './Space';

function CategoryList(props) {
    const { items } = props;
    return items && items.length > 0 ? (
        <Section type={'slim'} layout={'column'}>
            <Space />
            <Categories>
                {items.map(item => (
                    <Category key={item._key} to={`/${item.route.slug.current}`} background={imageUrlFor(item.image).url()}>
                        <h2>{item.route.page.title}</h2>
                    </Category>
                ))}
            </Categories>
            <Space />
        </Section>
    ) : null;
}

export default CategoryList;

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  grid-gap: 16px;
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    grid-gap: 24px;
    margin-bottom: 24px;
    width: 90%;
  }

  @media (min-width: 1024px) {
    grid-gap: 32px;
    margin-bottom: 32px;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  @media (min-width: 1240px) {
    grid-template-columns: repeat(5, minmax(0px, 1fr));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Category = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 170px;
  min-height: 170px;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  color: white;

  @media (min-width: 600px) {
    height: 100%;
    min-height: 300px;
  }

  h2 {
    z-index: 2;
    padding: 0 24px;
    font-size: 18px !important;

    @media (min-width: 600px) {
      font-size: 30px !important;
    }
  
    @media (min-width: 800px) {
      font-size: 28px !important;
    }
  
    @media (min-width: 1024px) {
      font-size: 24px !important;
    }
  
    @media (min-width: 1240px) {
      font-size: 28px !important;
    }
  }

  &:hover {
    color: white;

    &:after {
      opacity: 0.5;
    }
  }

  &:active {
    transform: scale(.98);
    color: white;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: black;
    opacity: 0.3;
    z-index: 0;
  }

  .corner-ribbon{
    width: 120px;
    background: #7ab627;
    position: absolute;
    top: 5px;
    right: -40px;
    text-align: center;
    font-size: 11px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 1px;
    color: white;
    transform: rotate(45deg);
    z-index: 4;
  }
  
  .corner-ribbon.shadow{
    box-shadow: 0 0 3px rgba(0,0,0,.3);
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.type === 'slim' ? '90%' : '100%'};
  max-width: ${props => props.type === 'slim' ? '1420px' : '100%'};

  @media (min-width: 1024px) {
    align-items: center;
    flex-direction: ${props => props.layout === 'row' ? 'row' : 'column'};
  }

  h1 {
    font-size: 32px;
    margin: 0 0 16px 0;
    font-weight: 900;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 38px;
    }
  }

  h2 {
    font-size: 26px;
    margin: 16px 0;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 22px;
    margin: 16px 0;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 26px;
    }
  }

  h4 {
    font-size: 18px;
    margin: 16px 0;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 22px;
    }
  }

  h5 {
    font-size: 18px;
    margin: 16px 0;
  }

  h6 {
    font-size: 16px;
    margin: 16px 0;
  }

  p {
    font-size: 18px;
    margin: 16px 0;
  }
`;