import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from './Button';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Update the disableSubmit state based on name, email, and recaptchaVerified
    const isFormValid = name.trim() !== '' && email.trim() !== '' && recaptchaVerified;
    setDisableSubmit(!isFormValid);
  }, [name, email, recaptchaVerified]); // This effect depends on name, email, and recaptchaVerified

  const onRecaptchaChange = (value) => {
    // value is null if the captcha expired and a token if verified
    setRecaptchaVerified(!!value);
  };

  return (
    <form name="contactus" method="POST" data-netlify="true" data-netlify-recaptcha="true">
      <input type="hidden" name="form-name" value="contactus" />
      <FormWrapper>
        <FormItem>
          <span>Required *</span>
          <input
            type="text"
            placeholder="Name:"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormItem>

        <FormItem>
          <input type="tel" placeholder="Contact number:" name="number" />
        </FormItem>

        <FormItem>
          <span>Required *</span>
          <input
            type="email"
            placeholder="Email address:"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormItem>

        <FormItem>
          <select name="reason">
            <option>Please select reason for your message</option>
            <option>Individual repairs</option>
            <option>Basic service</option>
            <option>Main service</option>
            <option>Overhaul</option>
            <option>Annual service package 1</option>
            <option>Annual service package 2</option>
            <option>Shimano Di2</option>
            <option>Pre-Delivery inspection</option>
            <option>Custom bike build</option>
            <option>E-Bike service</option>
            <option>Spin bike servicing</option>
            <option>Insurance/Warranty reports</option>
            <option>Event support</option>
            <option>Corporate/Fleet servicing</option>
            <option>Other</option>
          </select>
        </FormItem>

        <FormItem>
          <input type="text" placeholder="Address:" name="address" />
        </FormItem>

        <FormItem>
          <input type="text" placeholder="Postcode:" name="postcode" />
        </FormItem>

        <FormItem>
          <textarea placeholder="Message:" name="message"></textarea>
        </FormItem>

        <FormItem style={{alignItems: 'center', justifyContent: 'center', height: '130px'}}>
          <ReCAPTCHA
            sitekey={process.env.SITE_RECAPTCHA_KEY}
            onChange={onRecaptchaChange}
          />
        </FormItem>

      </FormWrapper>
      <FormButtons>
        <Button text={'Send message'} type={'submit'} disabled={disableSubmit} />
      </FormButtons>
    </form>
  );
}

export default Contact;

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  padding: 4px 16px 8px 16px;

  @media (min-width: 800px) {
    min-width: 45%;
    width: 50%;
  }

  input, textarea, select {
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
    font-weight: 500;
    line-height: 1.3;
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 18px;
    padding: 4px 16px 8px 16px;
    background: #f0f4f5;
    border-radius: 10px;
    height: 60px;
    color: #424242;
    margin: 8px;

    &:focus-visible {
      outline: #14A58A auto 1px;
    }
  }

  textarea {
    height: 120px;
  }

  span {
    color: red;
    padding: 0px 0px 0px 10px;
  }
`;

const FormButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin: 24px 0px 0;

  button {
    margin: 0;

    &:first-of-type {
      margin: 24px 0 16px 0;
    }

    @media (min-width: 800px) {
      &:first-of-type {
        margin: 0 16px 0 auto;
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    margin: 24px 8px 0;
  }
`;
