import React from 'react';
import styled from 'styled-components';
import { imageUrlFor } from "../lib/image-url";

function Image(props) {
  const { image, asset, src, alt, caption } = props;
  if (src === 'https://cdn.sanity.io/images/9xl3g5ub/production/233079487f18127fc863c8b3cf5c182b0a101029-1280x432.webp') {
    console.log('caption: ', props);
  }
  
  return image ?
    <ImageElement src={imageUrlFor(image).url()} alt={image.alt} /> :
    <ImageElement src={asset?.url || src} alt={alt ? alt : 'Image description'} />;
}

export default Image;

const ImageElement = styled.img`
    display: flex;
    width: 100%;
    border-radius: 10px;
`;
